@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	body {
		@apply h-full bg-white dark:bg-darkGrey h-10 dark:text-white overflow-x-hidden;
	}

	#__next {
		@apply flex h-full min-h-full flex-col;
	}

	.link {
		@apply -mx-2 rounded py-1 px-2 hover:bg-gray-200 hover:text-black focus:bg-gray-200 focus:text-black focus:outline-none  dark:hover:bg-gray-800 dark:hover:text-white dark:focus:bg-gray-800 dark:focus:text-white;
	}

	.link-visible {
		@apply -mx-2 rounded bg-gray-200 py-1 px-2 hover:bg-gray-400 hover:text-black focus:bg-gray-200 focus:text-black focus:outline-none dark:bg-gray-800 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:bg-gray-800 dark:focus:text-white;
	}

	h2,
	h3 {
		scroll-margin-top: 100px;
	}

	select {
		@apply w-full cursor-pointer appearance-none border-none bg-transparent font-sans;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
	}
	::-webkit-progress-value {
		background-color: orange;
	}
	.player-seeker {
		@apply h-1.5 cursor-pointer appearance-none rounded-tr rounded-tl bg-transparent bg-none;
	}
	.player-seeker::-moz-range-track {
		@apply h-full rounded-tr rounded-tl bg-gray-600;
	}

	.player-seeker::-webkit-slider-runnable-track {
		@apply h-full rounded-tr rounded-tl bg-[#9C0AA3] bg-opacity-70;
	}

	.player-seeker::-moz-range-progress {
		@apply h-full rounded-tr rounded-tl bg-[#9C0AA3];
	}

	.player-seeker::-webkit-slider-thumb {
		-webkit-appearance: none;
		@apply -mt-1 h-4 w-4 rounded-full border-none bg-[#9C0AA3];
	}
	.player-seeker::-moz-range-thumb {
		-webkit-appearance: none;
		@apply h-4 w-4 rounded-full border-none bg-[#9C0AA3];
	}

	:root {
		--select-border: #777;
		--select-focus: blue;
		--select-arrow: var(--select-border);
	}

	.sun-editor-editable {
		@apply dark:bg-darkGrey !important;
	}
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}
